import { getField, updateField } from 'vuex-map-fields';
import {
  mixinDialogMutations,
  mixinDialogActions,
  mixinDialogGetters,
} from '../../mixins/vuex_dialogs';

export default {
  namespaced: true,
  state: {
    profile: {},
    loading: {
      profile: false,
    },
    dialogs: {
      backupCodes: false,
      apply: false,
      changeDevice: false,
    },
    twoFactor: {
      applyType: '',
      backupCodes: '',
      username: '',
      password: '',
      qr: '',
      secretKey: '',
    },
  },
  getters: {
    ...mixinDialogGetters,
    getField,
    dialogs: (state) => state.dialogs,
    profile: (state) => state.profile,
    loading: (state) => state.loading,
    twoFactor: (state) => state.twoFactor,
  },
  mutations: {
    ...mixinDialogMutations,
    updateField,

    SET_PROFILE: (state, payload) => {
      state.profile = payload;
    },

    SET_LOADING: (state, { key, value }) => {
      state.loading[key] = value;
    },

    SET_TWO_FACTOR: (state, payload) => {
      state.twoFactor = {
        ...state.twoFactor,
        ...payload,
      };
    },

    UPDATE_PROFILE_LOCALLY: (state, payload) => {
      state.profile = {
        ...state.profile,
        ...payload,
      };
    },
  },

  actions: {
    ...mixinDialogActions,

    async loadProfile({ commit }) {
      try {
        commit('SET_LOADING', { key: 'profile', value: true });

        const response = await this._vm.api.get('/profile');

        if (response.status === 200 && response.data && response.data.data) {
          commit('SET_PROFILE', response.data.data);
        }
      } finally {
        commit('SET_LOADING', { key: 'profile', value: false });
      }
    },

    async setLoading({ commit }, data) {
      commit('SET_LOADING', data);
    },

    async updateProfileLocally({ commit }, data) {
      commit('UPDATE_PROFILE_LOCALLY', data);
    },

    async setTwoFactor({ commit }, data) {
      commit('SET_TWO_FACTOR', data);
    },
  },
};
