import { sha256 } from 'js-sha256';
import Vue from 'vue';

const getServerTime = async () => {
  try {
    const response = await Vue.prototype.api.get('/get-server-time');
    if (response?.data?.timestamp) {
      return response.data.timestamp;
    }

    console.error('Invalid server response:', response);
    return null;
  } catch (error) {
    console.error('Error fetching server time:', error);
    return null;
  }
};

export default async function getMathHeaders() {
  const mathTask = 17 + 36;
  const currentTimestamp = await getServerTime();

  if (currentTimestamp === null) {
    throw new Error('Could not synchronize with server time');
  }
  const hash = sha256(`${mathTask}:${currentTimestamp}`).toString();
  return { 'X-Math-Hash': hash, 'X-Math-Timestamp': currentTimestamp };
}
