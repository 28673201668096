import { getField, updateField } from 'vuex-map-fields';
import {
  mixinDialogMutations,
  mixinDialogActions,
  mixinDialogGetters,
} from '@/mixins/vuex_dialogs';

export default {
  namespaced: true,
  state: {
    profile: {},
    innerHeight: 0,
    dialogs: {
      alert: false,
      error: false,
    },
    alert: {
      color: '',
      message: '',
    },
    error: {},
    loading: {
      profile: false,
    },
  },
  getters: {
    ...mixinDialogGetters,
    getField,

    profile: (state) => state.profile,
    innerHeight: (state) => state.innerHeight,
    alert: (state) => state.alert,
    error: (state) => state.error,
    loading: (state) => state.loading,
  },
  mutations: {
    ...mixinDialogMutations,
    updateField,

    SET_ALERT: (state, alert) => {
      state.alert = alert;
    },

    OPEN_ALERT: (state) => {
      state.dialogs.alert = true;
    },

    CLOSE_ALERT: (state) => {
      state.dialogs.alert = false;
    },

    SET_ERROR: (state, payload) => {
      state.error[payload.key] = payload.value;
    },

    SET_PROFILE: (state, payload) => {
      state.profile = payload || {};
    },

    SET_LOADING: (state, { key, value }) => {
      state.loading[key] = value;
    },
  },

  actions: {
    ...mixinDialogActions,

    async alert(context, alert) {
      context.commit('CLOSE_ALERT');
      context.commit('SET_ALERT', alert);
      context.commit('OPEN_ALERT');
      setTimeout(() => {
        context.commit('CLOSE_ALERT');
      }, 5000);
    },

    async closeAlert(context) {
      context.commit('CLOSE_ALERT');
    },

    async setError(context, error) {
      context.commit('SET_ERROR', error);
    },

    async loadProfile(context) {
      try {
        context.commit('SET_LOADING', { key: 'profile', value: true });

        const response = await this._vm.api.get('/admin/profile');

        if (response.status === 200 && response.data && response.data.data) {
          context.commit('SET_PROFILE', response.data.data);
          localStorage.setItem('profile', JSON.stringify(response.data.data));
        }
      } finally {
        context.commit('SET_LOADING', { key: 'profile', value: false });
      }
    },
  },
};
