import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
  if (localStorage.getItem('apiToken')) {
    return next();
  }
  return next('/login');
};

const ifNotAuthenticated = (to, from, next) => {
  if (!localStorage.getItem('apiToken')) {
    return next();
  }
  return next('/');
};

const routes = [
  {
    path: '/',
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/Users.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/promocodes',
    name: 'Promocodes',
    component: () => import('@/views/Promocodes.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/releases',
    name: 'Releases',
    component: () => import('@/views/Releases.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/browser_builds',
    name: 'BrowserBuilds',
    component: () => import('@/views/BrowserBuilds.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/admins',
    name: 'Admins',
    component: () => import('@/views/Admins.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/logs',
    name: 'Logs',
    component: () => import('@/views/Logs.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/browser_profiles',
    name: 'BrowserProfiles',
    component: () => import('@/views/BrowserProfiles.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/accesses',
    name: 'Accesses',
    component: () => import('@/views/Accesses.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/coinbase',
    name: 'Coinbase',
    component: () => import('@/views/Coinbase.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/refbringers',
    name: 'Refbringers',
    component: () => import('@/views/Refbringers.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/branches',
    name: 'Branches',
    component: () => import('@/views/Branches.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/recurrent-payments',
    name: 'recurrentPayments',
    component: () => import('@/views/RecurrentPayments.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/subscription-renewal',
    name: 'SubscriptionRenewal',
    component: () => import('@/views/SubscriptionRenewal.vue'),
    beforeEnter: ifAuthenticated,
  },

  {
    path: '/finance-logs',
    name: 'Finance Logs',
    component: () => import('@/views/FinanceLogs.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import('@/views/Invoices.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/currencies',
    name: 'Currencies',
    component: () => import('../views/Currencies.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/captcha',
    name: 'Captcha',
    component: () => import('../views/Captcha.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/running-time',
    name: 'Browser Profile Running Time',
    component: () => import('@/views/RunningTime.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/feature-flags',
    name: 'FeatureFlags',
    component: () => import('@/views/FeatureFlags.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/Settings.vue'),
    beforeEnter: ifAuthenticated,
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
