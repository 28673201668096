export default {
  cardColor: '#121212',
  cardBorder: '1px solid #4B4B4B',
  cardBorderRadius: '4',
  inputTextColor: '#FFFFFF',
  placeHolderColor: '#4B4B4B',
  msgTextColor: '#B2B2B2',
  invalidMsgTextColor: '#FF5252',
  msgTextFont: "'Verdana'",
  inputBackgroundColor: '#121212',
  inputBorderColor: {
    byDefault: '#4B4B4B',
    hover: '#4B4B4B',
    active: '#4B4B4B',
  },
  buttonIconColor: {
    refresh: '#4B4B4B',
    verify: '#3874CB',
    success: '#4CAF50',
    fail: '#FF5252',
    audio: '#4B4B4B',
    audiofocus: '#3874CB',
  },
};
