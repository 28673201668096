import axios from 'axios';
import logOutMixin from '@/mixins/logOut';
import getMathHeaders from '@/helpers/common/axiosHeaders';

export default {
  mixins: [
    logOutMixin,
  ],

  data() {
    return {
      failedRequestsQueue: [],
      isRefreshing: false,
    };
  },

  methods: {
    shouldRefreshToken(error) {
      return error.response?.status === 401
        && localStorage.getItem('refreshToken')
        && this.$route.name !== 'Login';
    },

    shouldLogOut(error) {
      return error.response?.status === 401 || error.message === 'Network Error';
    },

    emailExists(error) {
      return error.response?.status === 422 && error.response?.data?.error?.text === 'validation.unique';
    },

    showErrorDialog(error) {
      return error.response.status !== 400 && error.response.status !== 429;
    },

    handleResponseError(responseError) {
      if (this.shouldRefreshToken(responseError)) {
        const originalRequest = responseError.config;
        let retryRequest = false;

        const processFailedRequestsQueue = (error, token = null) => {
          this.failedRequestsQueue.forEach((request) => {
            if (error) {
              request.reject(error);
            } else {
              request.resolve(token);
            }
          });

          this.failedRequestsQueue = [];
        };

        if (!retryRequest) {
          if (this.isRefreshing) {
            return new Promise(((resolve, reject) => {
              this.failedRequestsQueue.push({ resolve, reject });
            }))
              .then((token) => {
                originalRequest.headers.Authorization = `Bearer ${token}`;
                return axios(originalRequest);
              })
              .catch((error) => Promise.reject(error));
          }

          retryRequest = true;
          this.isRefreshing = true;

          return axios({
            method: 'post',
            url: `${responseError.config.baseURL}/auth/refreshToken`,
            data: {
              refreshToken: localStorage.getItem('refreshToken'),
            },
            headers: {
              ...getMathHeaders(),
              Authorization: `Bearer ${localStorage.getItem('apiToken')}`,
            },
          })
            .then(({ data }) => {
              originalRequest.headers.Authorization = `Bearer ${data.token}`;
              this.api.defaults.headers.Authorization = `Bearer ${data.token}`;
              localStorage.setItem('apiToken', data.token);
              localStorage.setItem('refreshToken', data.refresh_token);
              processFailedRequestsQueue(null, data.token);

              return axios(originalRequest);
            })
            .catch((error) => {
              processFailedRequestsQueue(error, null);

              if (error.response?.status === 401) {
                this.logOut();
              }

              return error;
            })
            .finally(() => {
              this.isRefreshing = false;
            });
        }
      } else if (this.shouldLogOut(responseError)) {
        this.logOut();
      } else if (this.emailExists(responseError)) {
        this.$store.dispatch('main/alert', {
          color: 'error',
          message: 'email уже существует',
        });
      } else if (this.showErrorDialog(responseError)) {
        let errorMessage = {
          request: {
            url: responseError.request?.responseURL,
            data: responseError.response?.config.data,
          },
          response: {
            status: responseError.response?.status,
            data: responseError.response?.data,
            message: responseError.message,
          },
        };

        if (responseError.response?.data?.error) {
          errorMessage = responseError.response.data.error;
        }

        this.$store.dispatch('main/setError', {
          key: 'message',
          value: JSON.stringify(errorMessage, null, ''),
        }).then(() => {
          this.$store.dispatch('main/openDialog', 'error');
        });
      }

      return Promise.reject(responseError);
    },
  },
};
