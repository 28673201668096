export default async function loadCaptcha() {
  try {
    const loadScript = (src) => new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.async = true;
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });

    await Promise.all([
      loadScript('https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js'),
      loadScript('https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js'),
    ]);
  } catch (error) {
    console.error('Error loading MTCaptcha scripts:', error);
  }
}
