import { getField, updateField } from 'vuex-map-fields';
import {
  mixinDialogActions,
  mixinDialogGetters,
  mixinDialogMutations,
} from '@/mixins/vuex_dialogs';

export default {
  namespaced: true,
  state: {
    featureFlags: {
      all: [],
      filtered: [],
      selected: [],
    },
    forMainDialog: {
      featureFlag: {},
      dialog: '',
    },
    forUsersDialog: {
      featureFlag: {},
      dialog: '',
    },
    forTeamsDialog: {
      featureFlag: {},
      dialog: '',
    },
    pagination: {
      currentPage: 1,
      perPage: 10,
    },
    dialogs: {
      mainDialog: false,
      usersDialog: false,
      teamsDialog: false,
    },
    loading: {
      mainTable: false,
    },
  },

  getters: {
    ...mixinDialogGetters,
    getField,

    featureFlags: (state) => state.featureFlags,
    forMainDialog: (state) => state.forMainDialog,
    forUsersDialog: (state) => state.forUsersDialog,
    forTeamsDialog: (state) => state.forTeamsDialog,
    loading: (state) => state.loading,
    dialogs: (state) => state.dialogs,
    pagination: (state) => state.pagination,
  },

  mutations: {
    ...mixinDialogMutations,
    updateField,

    SET_ALL_FEATURE_FLAGS: (state, payload) => {
      state.featureFlags.all = payload;
    },

    SET_PAGINATION: (state, payload) => {
      state.pagination = payload;
    },

    SET_LOADING: (state, payload) => {
      state.loading[payload.key] = payload.value;
    },

    CLEAR_FOR_DIALOG: (state) => {
      state.forMainDialog.featureFlag = {};
      state.forMainDialog.dialog = '';
      state.forUsersDialog.featureFlag = {};
      state.forUsersDialog.dialog = '';
    },

    CLEAR_FOR_USERS_DIALOG: (state) => {
      state.forUsersDialog.featureFlag = {};
      state.forUsersDialog.dialog = '';
    },

    CLEAR_FOR_TEAMS_DIALOG: (state) => {
      state.forTeamsDialog.featureFlag = {};
      state.forTeamsDialog.dialog = '';
    },

    SET_FEATURE_FLAG_FOR_MAIN_DIALOG: (state, payload) => {
      state.forMainDialog.featureFlag = payload.featureFlag;
      state.forMainDialog.dialog = payload.dialog;
    },

    SET_FEATURE_FLAG_FOR_USERS_DIALOG: (state, payload) => {
      state.forUsersDialog.featureFlag = payload.featureFlag;
      state.forUsersDialog.dialog = payload.dialog;
    },

    SET_FEATURE_FLAG_FOR_TEAMS_DIALOG: (state, payload) => {
      state.forTeamsDialog.featureFlag = payload.featureFlag;
      state.forTeamsDialog.dialog = payload.dialog;
    },
  },

  actions: {
    ...mixinDialogActions,

    async loadFeatureFlags(context, payload = null) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      try {
        let request = '/admin/feature-flags?';

        if (payload && payload.page) {
          request += `page=${payload.page}&`;
        }
        if (payload && payload.itemsPerPage) {
          request += `limit=${payload.itemsPerPage}&`;
        }
        if (payload && payload.query) {
          request += `query=${payload.query}&`;
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }

        if (payload === null || request.endsWith('?')) {
          request += 'page=1&limit=50';
        }

        const response = await this._vm.apiv2.get(request);

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            query: payload && payload.query ? payload.query : null,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_FEATURE_FLAGS', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async loadCurrentPage(context) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      try {
        let request = '/admin/feature-flags?';

        if (context.state.pagination.currentPage) {
          request += `page=${context.state.pagination.currentPage}&`;
        }
        if (context.state.pagination.perPage) {
          request += `limit=${context.state.pagination.perPage}&`;
        }
        if (context.state.pagination.query) {
          request += `query=${context.state.pagination.query}&`;
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }

        if (request.endsWith('?')) {
          request += 'page=1&limit=50';
        }

        const response = await this._vm.apiv2.get(request);

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            query: context.state.pagination.query ? context.state.pagination.query : null,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_FEATURE_FLAGS', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async setFeatureFlagForMainDialog(context, data = null) {
      const featureFlag = {
        featureKey: data?.featureKey ? data.featureKey : '',
        title: data?.title ? data.title : '',
        description: data?.description ? data.description : '',
        isEnableForAll: data?.isEnableForAll ? data.isEnableForAll : false,
        percentageByTeam: data?.percentageByTeam ? data.percentageByTeam : 0,
        percentageByUser: data?.percentageByUser ? data.percentageByUser : 0,
        criteria: data?.criteria ? JSON.stringify(data.criteria) : '',
        isForCore: data?.isForCore ? data.isForCore : false,
      };

      if (data && data.id) featureFlag.id = data.id;

      await context.commit('SET_FEATURE_FLAG_FOR_MAIN_DIALOG', {
        featureFlag,
        dialog: data === null ? 'add' : 'edit',
      });
    },

    async setFeatureFlagForUsersDialog(context, data = null) {
      const featureFlag = {
        id: data.id,
        featureKey: data.featureKey,
        users: data?.users ? data.users : [],
      };

      await context.commit('SET_FEATURE_FLAG_FOR_USERS_DIALOG', {
        featureFlag,
        dialog: data.dialogMode ? data.dialogMode : 'edit',
      });
    },

    async setFeatureFlagForTeamsDialog(context, data = null) {
      const featureFlag = {
        id: data.id,
        featureKey: data.featureKey,
        teams: data?.teams ? data.teams : [],
      };

      await context.commit('SET_FEATURE_FLAG_FOR_TEAMS_DIALOG', {
        featureFlag,
        dialog: data.dialogMode ? data.dialogMode : 'edit',
      });
    },

    async clearForDialog(context) {
      await context.commit('CLEAR_FOR_DIALOG');
    },

    async clearForUsersDialog(context) {
      await context.commit('CLEAR_FOR_USERS_DIALOG');
    },

    async clearForTeamsDialog(context) {
      await context.commit('CLEAR_FOR_TEAMS_DIALOG');
    },

    async setPagination(context, payload) {
      const { response } = payload;
      const { query } = payload;
      const pagination = {
        currentPage: parseInt(response.meta?.current_page, 10),
        lastPage: parseInt(response.meta?.last_page, 10),
        firstPageUrl: response.links?.first,
        nextPageUrl: response.links?.next,
        prevPageUrl: response.links?.prev,
        lastPageUrl: response.links?.last,
        links: response.links,
        perPage: parseInt(response.meta?.per_page, 10),
        total: parseInt(response.meta?.total, 10),
        from: parseInt(response.meta?.from, 10),
        to: parseInt(response.meta?.to, 10),
        query,
      };

      await context.commit('SET_PAGINATION', pagination);
    },
  },
};
