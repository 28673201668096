/* eslint-disable import/extensions */
import Vuex from 'vuex';
import Vue from 'vue';

import main from './modules/main.js';
import users from './modules/users.js';
import promocodes from './modules/promocodes.js';
import releases from './modules/releases.js';
import browserBuilds from './modules/browserBuilds.js';
import browserProfiles from './modules/browserProfiles.js';
import admins from './modules/admins.js';
import recurrentPayments from './modules/recurrentPayments.js';
import financeLogs from './modules/financeLogs.js';
import invoices from './modules/invoices.js';
import logs from './modules/logs.js';
import currencies from './modules/currencies.js';
import runningTime from './modules/runningTime';
import settings from './modules/settings';
import captcha from './modules/captcha';
import featureFlags from './modules/featureFlags';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    main,
    users,
    promocodes,
    releases,
    browserBuilds,
    browserProfiles,
    admins,
    recurrentPayments,
    financeLogs,
    invoices,
    logs,
    currencies,
    runningTime,
    settings,
    captcha,
    featureFlags,
  },
});
