/* eslint-disable no-restricted-syntax */
import { getField, updateField } from 'vuex-map-fields';
// import login from '@/views/Login.vue';

export default {
  namespaced: true,
  state: {
    browserProfiles: {
      all: [],
      filtered: [],
      selected: [],
    },
    forMainDialog: {
      profile: {},
      dialog: '',
    },
    pagination: {
      currentPage: 1,
      perPage: 10,
    },
    loading: {
      mainTable: false,
    },
    topbarSearch: {
      searchInput: '',
      searchInputName: '',
      searchInputUser: '',
    },
    searchParams: {
      date: null,
      env: null,
      search: null,
      searchUser: null,
      searchName: null,
      type: null,
      page: 1,
      itemsPerPage: 50,
    },
  },
  getters: {
    getField,

    browserProfiles: (state) => state.browserProfiles,
    search: (state) => state.search,
    loading: (state) => state.loading,
    topbarSearch: (state) => state.topbarSearch,
    pagination: (state) => state.pagination,
  },
  mutations: {
    updateField,

    SET_ALL_BROWSER_PROFILES: (state, payload) => {
      state.browserProfiles.all = payload;
    },

    SET_SELECTED: (state, payload) => {
      state.browserProfiles.selected = payload;
    },

    SET_PAGINATION: (state, payload) => {
      state.pagination = payload;
    },

    SET_LOADING: (state, payload) => {
      state.loading[payload.key] = payload.value;
    },
    SET_SEARCH: (state, payload) => {
      state.search = payload;
    },
  },

  actions: {
    async loadBrowserProfiles(context) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      const payload = { ...context.state.searchParams };
      try {
        let request = '/admin/browser_profiles?';

        if (payload && payload.page) {
          request += `page=${payload.page}&`;
        }
        if (payload && payload.itemsPerPage) {
          request += `limit=${payload.itemsPerPage}&`;
        }
        if (payload && payload.env) {
          request += `env=${payload.env}&`;
        }
        if ((payload && payload.searchName) || (context?.state?.pagination?.searchName)) {
          request += `search=${payload?.searchName || context?.state?.pagination?.searchName}&`;
        }
        if ((payload && payload.searchUser) || (context?.state?.pagination?.searchUser)) {
          request += `username=${payload?.searchUser || context?.state?.pagination?.searchUser}&`;
        }
        if (payload && payload.date) {
          request += `date=${payload.date}&`;
        }
        if (payload && payload.type) {
          request += `type=${payload.type}&`;
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }

        if (payload === null || request.endsWith('?')) {
          request += 'page=1&limit=50';
        }

        const response = await this._vm.api.get(request);

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            searchName: payload && payload.searchName ? payload.searchName : null,
            searchUser: payload && payload.searchUser ? payload.searchUser : null,
            env: payload && payload.env ? payload.env : null,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_BROWSER_PROFILES', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async loadCurrentPage(context) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      try {
        let request = '/admin/browser_profiles?';

        if (context.state.pagination.currentPage) {
          request += `page=${context.state.pagination.currentPage}&`;
        }
        if (context.state.pagination.perPage) {
          request += `limit=${context.state.pagination.perPage}&`;
        }
        if (context.state.pagination.env) {
          request += `env=${context.state.pagination.env}&`;
        }
        if (context.state.pagination.searchName) {
          request += `search=${context.state.pagination.searchName}&`;
        }
        if (context.state.pagination.searchUser) {
          request += `username=${context.state.pagination.searchUser}&`;
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }

        if (request.endsWith('?')) {
          request += 'page=1&limit=50';
        }

        const response = await this._vm.api.get(request);

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            searchName: context.state.pagination.searchName ? context.state.pagination.searchName : null,
            searchUser: context.state.pagination.searchUser ? context.state.pagination.searchUser : null,
            env: context.state.pagination.env ? context.state.pagination.env : null,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_BROWSER_PROFILES', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async setPagination(context, payload) {
      const { response } = payload;
      const { searchName, searchUser } = payload;

      const pagination = {
        currentPage: parseInt(response.current_page, 10),
        lastPage: parseInt(response.last_page, 10),
        firstPageUrl: response.first_page_url,
        nextPageUrl: response.next_page_url,
        prevPageUrl: response.prev_page_url,
        lastPageUrl: response.last_page_url,
        links: response.links,
        perPage: parseInt(response.per_page, 10),
        total: parseInt(response.total, 10),
        from: parseInt(response.from, 10),
        to: parseInt(response.to, 10),
        searchName,
        searchUser,
      };

      await context.commit('SET_PAGINATION', pagination);
    },

    async clearSelected(context) {
      context.commit('SET_SELECTED', []);
    },
  },
};
