/* eslint-disable no-restricted-syntax */
import { getField, updateField } from 'vuex-map-fields';
import {
  mixinDialogMutations,
  mixinDialogActions,
  mixinDialogGetters,
} from '../../mixins/vuex_dialogs';

export default {
  namespaced: true,
  state: {
    invoices: {
      all: [],
      filtered: [],
      selected: [],
      forDialog: {
        user: {},
        dialog: '',
      },
    },
    form: {
      id: '',
      searchInput: '',
      limit: '',
      username: '',
      teamId: '',
      teamName: '',
      query: '',
      sum: '',
      subscriptionId: '',
      invoiceId: '',
      orderId: '',
      provider: 'all',
      plan: 'all',
      currency: 'all',
      coin: 'all',
      status: 'all',
      createdAt: '',
      sortBy: 'created_at',
      order: 'DESC',
    },
    statuses: [
      'all',
      'new',
      'success',
      'expired',
      'failed',
      'refund',
      'converted',
    ],
    providers: [
      'all',
      'cloudpayments',
      'coinbase',
      'manual',
      'upgrade',
      'iban',
      'dolphinpay',
      'cryptadium',
      'capitalist',
      'stripe',
      'unlimint',
      'inxy',
      'cryptomus',
    ],
    plans: [
      'all',
      'trial',
      'trial',
      'free',
      'base',
      'team',
      'enterprise',
      'custom',
    ],
    currencies: [
      'all',
      'EUR',
      'USD',
      'RUB',
      'BRL',
    ],
    coins: [
      'all',
      'ETH',
      'USDT',
      'BTC',
      'LTC',
      'ETH',
      'DOGE',
      'USDT (BEP20)',
      'USDT (ERC20)',
      'USDT (TRC20)',
      'ALIPAYPLUS',
      'PIX',
      'PICPAY',
    ],
    pagination: {
      currentPage: 1,
      perPage: 50,
    },
    loading: {
      mainTable: false,
    },
    transactions: [],
    team: null,
    profileTitle: null,
  },

  getters: {
    ...mixinDialogGetters,
    getField,
    loading: (state) => state.loading,
    dialogs: (state) => state.dialogs,
    form: (state) => state.form,
    pagination: (state) => state.pagination,
    userTransactions: (state) => state.userTransactions,
    team: (state) => state.team,
    profileTitle: (state) => state.profileTitle,
    providers: (state) => state.providers,
    plans: (state) => state.plans,
    statuses: (state) => state.statuses,
    coins: (state) => state.coins,
    currencies: (state) => state.currencies,
  },

  mutations: {
    ...mixinDialogMutations,
    updateField,

    SET_ALL_INVOICES: (state, payload) => {
      state.invoices.all = payload;
    },

    SET_PAGINATION: (state, payload) => {
      state.pagination = payload;
    },

    SET_LOADING: (state, payload) => {
      state.loading[payload.key] = payload.value;
    },

    CLEAR_FOR_DIALOG: (state) => {
      state.users.forDialog.user = {};
      state.users.forDialog.dialog = '';
    },

    SET_FOR_DIALOG: (state, payload) => {
      state.users.forDialog.user = payload.user;
      state.users.forDialog.dialog = payload.dialog;
    },
    SET_TEAM: (state, payload) => {
      state.team = payload;
    },
    SET_PROFILE_TITLE: (state, payload) => {
      state.profileTitle = payload;
    },
    SET_FILTER: (state, payload) => {
      state.form.sortBy = payload.sortBy;
      state.form.order = payload.order;
    },
  },

  actions: {
    ...mixinDialogActions,
    async loadInvoices(context, payload = null) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });
      console.error(payload);

      try {
        let request = '/admin/invoices?';

        if (payload && payload.page) {
          request += `page=${payload.page}&`;
        }
        if (payload && payload.itemsPerPage) {
          request += `limit=${payload.itemsPerPage}&`;
        }
        if (payload && payload.query) {
          request += `query=${payload.query}&`;
        }
        if (payload && payload.username) {
          request += `username=${payload.username}&`;
        }
        if (payload && payload.teamName) {
          request += `teamName=${payload.teamName}&`;
        }
        if (payload && payload.createdAt) {
          request += `createdAt=${payload.createdAt}&`;
        }
        if (payload && payload.provider && payload.provider !== 'all') {
          request += `provider=${payload.provider}&`;
        }
        if (payload && payload.coin && payload.coin !== 'all') {
          request += `coin=${payload.coin}&`;
        }
        if (payload && payload.currency && payload.currency !== 'all') {
          request += `currency=${payload.currency}&`;
        }
        if (payload && payload.id) {
          request += `id=${payload.id}&`;
        }
        if (payload && payload.orderId) {
          request += `orderId=${payload.orderId}&`;
        }
        if (payload && payload.sum) {
          request += `sum=${payload.sum}&`;
        }
        if (payload && payload.teamId) {
          request += `teamId=${payload.teamId}&`;
        }
        if (payload && payload.status && payload.status !== 'all') {
          request += `status=${payload.status}&`;
        }
        if (payload && payload.plan && payload.plan !== 'all') {
          request += `plan=${payload.plan}&`;
        }
        if (payload && payload.sortBy) {
          request += `sortBy=${payload.sortBy}&`;
        }
        if (payload && payload.order) {
          request += `order=${payload.order}&`;
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }
        if (payload === null || request.endsWith('?')) {
          request += 'page=1&limit=50';
        }
        const response = await this._vm.api.get(request);
        console.log(response);
        console.log(response.data);
        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            query: context.state.pagination.query ? context.state.pagination.query : null,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_INVOICES', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },
    async loadCurrentPage(context) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      try {
        let request = '/admin/invoices?';

        if (context.state.pagination.currentPage) {
          request += `page=${context.state.pagination.currentPage}&`;
        }
        if (context.state.pagination.perPage) {
          request += `limit=${context.state.pagination.perPage}&`;
        }
        if (context.state.pagination.query) {
          request += `query=${context.state.pagination.query}&`;
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }

        if (request.endsWith('?')) {
          request += 'page=1&limit=50';
        }

        const response = await this._vm.api.get(request);

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            query: context.state.pagination.query ? context.state.pagination.query : null,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_INVOICES', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },
    async setFilter(context, data) {
      await context.commit('SET_FILTER', data);
    },
    async setPagination(context, payload) {
      const { response } = payload;
      const { query } = payload;

      const pagination = {
        currentPage: parseInt(response.current_page, 10),
        lastPage: parseInt(response.last_page, 10),
        firstPageUrl: response.first_page_url,
        nextPageUrl: response.next_page_url,
        prevPageUrl: response.prev_page_url,
        lastPageUrl: response.last_page_url,
        links: response.links,
        perPage: parseInt(response.per_page, 10),
        total: parseInt(response.total, 10),
        from: parseInt(response.from, 10),
        to: parseInt(response.to, 10),
        query,
      };

      await context.commit('SET_PAGINATION', pagination);
    },
  },
};
