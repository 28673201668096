/* eslint-disable no-restricted-syntax */
import { getField, updateField } from 'vuex-map-fields';
import axios from 'axios';
import {
  mixinDialogMutations,
  mixinDialogActions,
  mixinDialogGetters,
} from '../../mixins/vuex_dialogs';

import router from '../../router';

export default {
  namespaced: true,
  state: {
    logs: {
      all: [],
      filtered: [],
      selected: [],
      forDialog: {
        user: {},
        dialog: '',
      },
    },
    pagination: {
      page: 1,
      per_page: 30,
    },
    dialogs: {
      add: false,
      changePassword: false,
    },
    loading: {
      mainTable: false,
    },
    filters: {
      level: null,
    },
    topbarSearch: {
      searchInput: '',
    },
  },

  getters: {
    ...mixinDialogGetters,
    getField,

    logs: (state) => state.logs,
    loading: (state) => state.loading,
    dialogs: (state) => state.dialogs,
    topbarSearch: (state) => state.topbarSearch,
    pagination: (state) => state.pagination,
    filters: (state) => state.filters,
  },

  mutations: {
    ...mixinDialogMutations,
    updateField,

    SET_ALL: (state, payload) => {
      state.logs.all = payload;
    },

    SET_SELECTED: (state, payload) => {
      state.logs.selected = payload;
    },

    SET_PAGINATION: (state, payload) => {
      state.pagination = payload;
    },

    SET_LOADING: (state, payload) => {
      state.loading[payload.key] = payload.value;
    },

    CLEAR_FOR_DIALOG: (state) => {
      state.logs.forDialog.user = {};
      state.logs.forDialog.dialog = '';
    },

    SET_FOR_DIALOG: (state, payload) => {
      state.logs.forDialog.user = payload.user;
      state.logs.forDialog.dialog = payload.dialog;
    },
  },

  actions: {
    ...mixinDialogActions,

    async loadLogs({ commit, state, dispatch }, data = {}) {
      try {
        commit('SET_LOADING', { key: 'mainTable', value: true });

        const { page, itemsPerPage } = data;
        const { page: statePage, per_page: statePerPage } = state.pagination;
        const { query } = router.currentRoute;

        let path = 'https://internal.vo4.co/search?';

        if (page) {
          path += `page=${page}&`;
        } else if (statePage) {
          path += `page=${statePage}&`;
        }
        if (itemsPerPage) {
          path += `limit=${itemsPerPage}&`;
        } else if (statePerPage) {
          path += `limit=${statePerPage}&`;
        }
        if (query) {
          if (query.env) {
            path += `env=${query.env}&`;
          } else {
            path += 'env=prod&';
          }
          if (query.level) {
            path += `level=${query.level}&`;
          }
          if (query.source) {
            path += `source=${query.source}&`;
          }
          if (query.browserProfileId) {
            path += `browserProfileId=${query.browserProfileId}&`;
          }
          if (query.username) {
            path += `username=${query.username}&`;
          }
          if (query.actionType) {
            path += `actionTypeSearch=${query.actionType}&`;
          }
          if (query.message) {
            path += `messageSearch=${query.message}&`;
          }
        }

        if (path[path.length - 1] === '&' || path[path.length - 1] === '?') {
          path = path.slice(0, path.length - 1);
        }

        const response = await axios({
          url: path,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apiToken')}`,
          },
        });
        const { status, data: responseData } = response;
        if (status === 200 && responseData && Array.isArray(responseData.data)) {
          commit('SET_ALL', responseData.data);
          dispatch('setPagination', responseData);
        } else {
          throw new Error('invalid response');
        }
      } catch (error) {
        console.log(error);
      } finally {
        commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async setPagination({ commit }, payload) {
      const {
        currentPage,
        lastPage,
        limit,
        size,
      } = payload;

      const from = currentPage * limit - limit;
      const to = currentPage * limit;

      const pagination = {
        currentPage: parseInt(currentPage, 10),
        lastPage: parseInt(lastPage, 10),
        perPage: parseInt(limit, 10),
        total: parseInt(size, 10),
        from: parseInt(from, 10),
        to: parseInt(to, 10),
      };

      await commit('SET_PAGINATION', pagination);
    },

    async clearForDialog(context) {
      await context.commit('CLEAR_FOR_DIALOG');
    },

    async setForDialog(context, data) {
      await context.commit('SET_FOR_DIALOG', data);
    },

    async clearSelected(context) {
      context.commit('SET_SELECTED', []);
    },
  },
};
