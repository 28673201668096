<template>
  <v-dialog
    scrollable
    :value="dialogs.error"
    width="600"
    @click:outside="$store.dispatch('main/closeDialog', 'error')"
    @keydown.esc="$store.dispatch('main/closeDialog', 'error')"
  >
    <v-card>
      <v-card-title class="headline">
        Ой! Что-то пошло не так
      </v-card-title>

      <v-card-text>
        <div>Передайте эти данные разработчику :</div>
        <v-row>
          <!-- ОШИБКА -->
          <v-col :cols="12">
            <v-textarea
              auto-grow
              :spellcheck="false"
              :value="error.message"
              style="font-size: 11px; line-height: 12px !important;"
            >
              <template #append>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      small
                      v-on="on"
                      @click="copy"
                    >
                      <v-icon :size="16">
                        mdi-content-copy
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Скопировать</span>
                </v-tooltip>
              </template>
            </v-textarea>
          </v-col>
          <!-- СКОПИРОВАТЬ -->
        </v-row>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="grey"
          @click="$store.dispatch('main/closeDialog', 'error')"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Error',

  computed: {
    ...mapGetters({
      dialogs: 'main/dialogs',
      error: 'main/error',
    }),
  },

  methods: {
    copy() {
      navigator.clipboard.writeText(this.error.message);
      this.$store.dispatch('main/alert', { color: 'success', message: 'Скопировал' });
    },
  },
};
</script>
<style>
#error-textarea {
  line-height: 14px;
}
</style>
