<template>
  <v-app>
    <sidebar v-if="$route.name !== 'Login'" />
    <alert v-if="dialogs.alert" />
    <error v-if="dialogs.error" />

    <v-main :style="{ 'padding': $route.name !== 'Login' ? '8px 8px 8px 212px' : '8px' }">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapGetters } from 'vuex';
import responseInterceptorMixin from '@/mixins/responseInterceptor';
import loadCaptcha from '@/helpers/common/captcha';
import captchaCustomStyle from '@/constants/authorization/captchaCustomStyle';
import captchaKeys from '@/constants/authorization/captchaKeys';
import Sidebar from './components/Sidebar.vue';
import Alert from './components/Alert.vue';
import Error from './components/Error.vue';

export default {
  name: 'App',

  components: {
    Sidebar,
    Alert,
    Error,
  },

  mixins: [
    responseInterceptorMixin,
  ],

  computed: {
    ...mapGetters({
      dialogs: 'main/dialogs',
    }),
    ...mapFields('main', ['innerHeight']),
  },

  async created() {
    window.mtcaptchaConfig = {
      sitekey: captchaKeys.MT_CAPTCHA_KEY_PROD,
      widgetSize: 'mini',
      theme: 'overcast',
      render: 'explicit',
      customStyle: captchaCustomStyle,
    };

    await loadCaptcha();

    this.setInnerHeight();
    this.setInterceptors();
    if (window.location.hash.search('login') === -1 && localStorage.getItem('apiToken')) {
      this.$store.dispatch('main/loadProfile');
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    setInnerHeight() {
      this.innerHeight = window.innerHeight;
      window.addEventListener('resize', this.handleResize);
    },

    handleResize(e) {
      this.innerHeight = e.target.innerHeight;
    },

    setInterceptors() {
      this.api.interceptors.response.use(
        (response) => response,
        this.handleResponseError,
      );
    },
  },
};
</script>

<style>
  .main-table-link {
    color: #2196F3;
    text-decoration: underline;
    cursor: pointer;
  }
  .centralize-append-icon .v-input__append-inner{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
</style>
