/* eslint-disable no-restricted-syntax */
import { getField, updateField } from 'vuex-map-fields';
import {
  mixinDialogMutations,
  mixinDialogActions,
  mixinDialogGetters,
} from '../../mixins/vuex_dialogs';

export default {
  namespaced: true,
  state: {
    admins: {
      all: [],
      filtered: [],
      selected: [],
    },
    forMainDialog: {
      admin: {},
      dialog: '',
    },
    pagination: {},
    dialogs: {
      mainDialog: false,
      showRefPaymentsHistory: false,
    },
    loading: {
      mainTable: false,
      deleteButton: false,
    },
    topbarSearch: {
      searchInput: '',
    },
  },
  getters: {
    ...mixinDialogGetters,
    getField,

    admins: (state) => state.admins,
    forMainDialog: (state) => state.forMainDialog,
    loading: (state) => state.loading,
    dialogs: (state) => state.dialogs,
    topbarSearch: (state) => state.topbarSearch,
    pagination: (state) => state.pagination,
  },
  mutations: {
    ...mixinDialogMutations,
    updateField,

    SET_ALL_ADMINS: (state, payload) => {
      state.admins.all = payload;
    },

    SET_SELECTED: (state, payload) => {
      state.admins.selected = payload;
    },

    SET_PAGINATION: (state, payload) => {
      state.pagination = payload;
    },

    SET_LOADING: (state, payload) => {
      state.loading[payload.key] = payload.value;
    },

    CLEAR_FOR_DIALOG: (state) => {
      state.forMainDialog.admin = {};
      state.forMainDialog.dialog = '';
    },

    SET_ADMIN_FOR_MAIN_DIALOG: (state, payload) => {
      state.forMainDialog.admin = payload.admin;
      state.forMainDialog.dialog = payload.dialog;
    },
  },

  actions: {
    ...mixinDialogActions,

    async loadAdmins(context, payload = null) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      try {
        let request = '/admin/admins?';

        if (payload && payload.page) {
          request += `page=${payload.page}&`;
        }
        if (payload && payload.itemsPerPage) {
          request += `limit=${payload.itemsPerPage}&`;
        }
        if (payload && payload.query) {
          request += `query=${payload.query}&`;
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }

        if (payload === null || request.endsWith('?')) {
          request += 'page=1&limit=50';
        }

        const response = await this._vm.api.get(request);

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            query: payload && payload.query ? payload.query : null,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_ADMINS', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async loadCurrentPage(context) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      try {
        let request = '/admin/admins?';

        if (context.state.pagination.currentPage) {
          request += `page=${context.state.pagination.currentPage}&`;
        }
        if (context.state.pagination.perPage) {
          request += `limit=${context.state.pagination.perPage}&`;
        }
        if (context.state.pagination.query) {
          request += `query=${context.state.pagination.query}&`;
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }

        if (request.endsWith('?')) {
          request += 'page=1&limit=50';
        }

        const response = await this._vm.api.get(request);

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            query: context.state.pagination.query ? context.state.pagination.query : null,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_ADMINS', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async setPagination(context, payload) {
      const { response } = payload;
      const { query } = payload;

      const pagination = {
        currentPage: parseInt(response.current_page, 10),
        lastPage: parseInt(response.last_page, 10),
        firstPageUrl: response.first_page_url,
        nextPageUrl: response.next_page_url,
        prevPageUrl: response.prev_page_url,
        lastPageUrl: response.last_page_url,
        links: response.links,
        perPage: parseInt(response.per_page, 10),
        total: parseInt(response.total, 10),
        from: parseInt(response.from, 10),
        to: parseInt(response.to, 10),
        query,
      };

      await context.commit('SET_PAGINATION', pagination);
    },

    async clearForDialog(context) {
      await context.commit('CLEAR_FOR_DIALOG');
    },

    async setAdminForMainDialog(context, data = {}) {
      const admin = {
        username: data.username || '',
        displayName: data.displayName || '',
        roleName: data.roleName || '',
        manageAdmins: data.manageAdmins || false,
        manageUsers: data.manageUsers || false,
        deleteTeam: data.deleteTeam || false,
        manageFinanceLogs: data.manageFinanceLogs || false,
        managePromocodes: data.managePromocodes || false,
        deletePromocodes: data.deletePromocodes || false,
        manageReleases: data.manageReleases || false,
        manageBrowserBuilds: data.manageBrowserBuilds || false,
        deleteBrowserBuilds: data.deleteBrowserBuilds || false,
        manageLogs: data.manageLogs || false,
        manageBrowserProfiles: data.manageBrowserProfiles || false,
        viewReferalBalance: data.viewReferalBalance || false,
        resetReferalBalance: data.resetReferalBalance || false,
        managePromoAccess: data.managePromoAccess || false,
        coinbase_invoices: data.coinbase_invoices || false,
        manageRefbringers: data.manageRefbringers || false,
        manageCaptcha: data.manageCaptcha || false,
      };

      if (data && data.id) admin.id = data.id;

      await context.commit('SET_ADMIN_FOR_MAIN_DIALOG', {
        admin,
        dialog: data.username ? 'edit' : 'add',
      });
    },

    async setLoading(context, payload) {
      context.commit('SET_LOADING', payload);
    },

    async clearSelected(context) {
      context.commit('SET_SELECTED', []);
    },
  },
};
