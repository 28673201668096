/* eslint-disable no-restricted-syntax */
import { getField, updateField } from 'vuex-map-fields';
import {
  mixinDialogMutations,
  mixinDialogActions,
  mixinDialogGetters,
} from '../../mixins/vuex_dialogs';

export default {
  namespaced: true,
  state: {
    payments: {
      all: [],
      filtered: [],
      selected: [],
      forDialog: {
        user: {},
        dialog: '',
      },
    },
    pagination: {
      currentPage: 1,
      perPage: 50,
    },
    loading: {
      mainTable: false,
    },
    topbarSearch: {
      searchInput: '',
    },
    userTransactions: [],
    team: null,
    profileTitle: null,
  },

  getters: {
    ...mixinDialogGetters,
    getField,
    loading: (state) => state.loading,
    dialogs: (state) => state.dialogs,
    topbarSearch: (state) => state.topbarSearch,
    pagination: (state) => state.pagination,
    userTransactions: (state) => state.userTransactions,
    team: (state) => state.team,
    profileTitle: (state) => state.profileTitle,
  },

  mutations: {
    ...mixinDialogMutations,
    updateField,

    SET_ALL_PAYMENTS: (state, payload) => {
      state.payments.all = payload;
    },

    SET_PAGINATION: (state, payload) => {
      state.pagination = payload;
    },

    SET_LOADING: (state, payload) => {
      state.loading[payload.key] = payload.value;
    },

    CLEAR_FOR_DIALOG: (state) => {
      state.users.forDialog.user = {};
      state.users.forDialog.dialog = '';
    },

    SET_FOR_DIALOG: (state, payload) => {
      state.users.forDialog.user = payload.user;
      state.users.forDialog.dialog = payload.dialog;
    },
    SET_TEAM: (state, payload) => {
      state.team = payload;
    },
    SET_PROFILE_TITLE: (state, payload) => {
      state.profileTitle = payload;
    },
  },

  actions: {
    ...mixinDialogActions,
    async loadRecurrentPayments(context, payload = null) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      try {
        let request = '/admin/subscription/cloudpayments?';

        if (payload && payload.page) {
          request += `page=${payload.page}&`;
        }
        if (payload && payload.itemsPerPage) {
          request += `limit=${payload.itemsPerPage}&`;
        }
        if (payload && payload.query) {
          request += `query=${payload.query}&`;
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }

        if (payload === null || request.endsWith('?')) {
          request += 'page=1&limit=50';
        }

        const response = await this._vm.api.get(request);
        console.log(response.data.teamId);
        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            query: payload && payload.query ? payload.query : null,
          };

          console.log(response);
          console.log(response.data.data);
          context.dispatch('setPagination', data);
          context.commit('SET_ALL_PAYMENTS', response.data.data);
          console.log('response.data.teamId', response.data.teamId);
          context.commit('SET_TEAM', response.data.teamId);
          context.commit('SET_PROFILE_TITLE', response.data.title);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },
    async loadCurrentPage(context) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      try {
        let request = '/admin/subscription/cloudpayments?';

        if (context.state.pagination.currentPage) {
          request += `page=${context.state.pagination.currentPage}&`;
        }
        if (context.state.pagination.perPage) {
          request += `limit=${context.state.pagination.perPage}&`;
        }
        if (context.state.pagination.query) {
          request += `query=${context.state.pagination.query}&`;
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }

        if (request.endsWith('?')) {
          request += 'page=1&limit=50';
        }

        const response = await this._vm.api.get(request);

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            query: context.state.pagination.query ? context.state.pagination.query : null,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_PAYMENTS', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },
  },
};
