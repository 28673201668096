/* eslint-disable no-restricted-syntax */
import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    currencies: {
      all: [],
      filtered: [],
      selected: [],
    },
    pagination: {
      currentPage: 1,
      perPage: 10,
    },
    loading: {
      mainTable: false,
    },
  },
  getters: {
    getField,
    currencies: (state) => state.currencies,
    loading: (state) => state.loading,
    pagination: (state) => state.pagination,
  },
  mutations: {
    updateField,

    SET_ALL_CURRENCIES: (state, payload) => {
      state.currencies.all = payload;
    },

    SET_SELECTED: (state, payload) => {
      state.currencies.selected = payload;
    },

    SET_PAGINATION: (state, payload) => {
      state.pagination = payload;
    },

    SET_LOADING: (state, payload) => {
      state.loading[payload.key] = payload.value;
    },
  },

  actions: {
    async loadCurrencies(context, payload = null) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      try {
        let request = '/admin/currencies?';
        if (payload && payload.date) {
          request += `date=${payload.date}&`;
        }
        if (payload && payload.itemsPerPage) {
          request += `limit=${payload.itemsPerPage}&`;
        }
        if (payload && payload.page) {
          request += `page=${payload.page}&`;
        }
        if (payload === null || request.endsWith('?')) {
          request += 'page=1&limit=50';
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }
        const response = await this._vm.api.get(request);

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            searchName: payload && payload.searchName ? payload.searchName : null,
            searchUser: payload && payload.searchUser ? payload.searchUser : null,
            env: payload && payload.env ? payload.env : null,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_CURRENCIES', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async loadCurrentPage(context) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      try {
        let request = '/admin/currencies?';

        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }

        if (request.endsWith('?')) {
          request += 'page=1&limit=50';
        }

        const response = await this._vm.api.get(request);

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            searchName: context.state.pagination.searchName ? context.state.pagination.searchName : null,
            searchUser: context.state.pagination.searchUser ? context.state.pagination.searchUser : null,
            env: context.state.pagination.env ? context.state.pagination.env : null,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_CURRENCIES', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async setPagination(context, payload) {
      const { response } = payload;
      const { search } = payload;

      const pagination = {
        currentPage: parseInt(response.current_page, 10),
        lastPage: parseInt(response.last_page, 10),
        firstPageUrl: response.first_page_url,
        nextPageUrl: response.next_page_url,
        prevPageUrl: response.prev_page_url,
        lastPageUrl: response.last_page_url,
        links: response.links,
        perPage: parseInt(response.per_page, 10),
        total: parseInt(response.total, 10),
        from: parseInt(response.from, 10),
        to: parseInt(response.to, 10),
        search,
      };

      await context.commit('SET_PAGINATION', pagination);
    },

    async clearSelected(context) {
      context.commit('SET_SELECTED', []);
    },
  },
};
