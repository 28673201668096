import { mixinDialogActions, mixinDialogGetters, mixinDialogMutations } from '@/mixins/vuex_dialogs';

export default {
  namespaced: true,
  state: {
    captchaInfo: {
      service: 'mtcaptcha',
      authorization: 0,
      registration: 0,
      forgotPassword: 0,
    },
    loader: false,
    successAlert: false,
    errorAlert: false,
  },

  getters: {
    ...mixinDialogGetters,
    captchaInfo: (state) => state.captchaInfo,
    loader: (state) => state.loader,
    successAlert: (state) => state.successAlert,
    errorAlert: (state) => state.errorAlert,
  },

  mutations: {
    ...mixinDialogMutations,
    SET_CAPTCHA_INFO: (state, payload) => {
      state.captchaInfo = {
        ...state.captchaInfo,
        ...payload,
      };
    },
    SET_LOADER: (state, payload) => {
      state.loader = payload;
    },
    SET_SUCCESS_ALERT: (state, payload) => {
      state.successAlert = payload;
    },
    SET_ERROR_ALERT: (state, payload) => {
      state.errorAlert = payload;
    },
  },

  actions: {
    ...mixinDialogActions,
    async getCaptchaInfo(context) {
      try {
        const response = await this._vm.api.get('/admin/captcha-settings/');
        if (response.status === 200 && response.data) {
          const { data } = response;
          context.commit('SET_CAPTCHA_INFO', data);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async handleSaveCaptcha(context) {
      context.commit('SET_LOADER', true);
      try {
        await this._vm.api.post('/admin/captcha-settings/', context.state.captchaInfo);
        context.commit('SET_SUCCESS_ALERT', true);
      } catch (err) {
        context.commit('SET_ERROR_ALERT', true);
      } finally {
        context.commit('SET_LOADER', false);
      }
    },
  },
};
